import React, { useState, useEffect } from "react";
import { getSliderVideo } from "../../Admin/Components/Api/Api";
import { imgurl } from "../../Admin/Components/Credentials/Credentials";

const Video_Sections = () => {
  const [aboutUsData, setAboutUsData] = useState(null);
  const [isImageLoaded, setIsImageLoaded] = useState(false);

  // Fetching slider data from the API
  const fetchAboutUsData = async () => {
    try {
      const data = await getSliderVideo();
      setAboutUsData(data.data.data.data[0]);
    } catch (error) {
      console.error("Error fetching About Us data:", error);
    }
  };

  useEffect(() => {
    fetchAboutUsData();
  }, []);

  // console.log("aboutUsData", aboutUsData);

  // Loading state
  if (!aboutUsData) {
    return (
      <div className="flex items-center justify-center h-screen bg-gray-100">
        <p className="text-lg font-semibold text-gray-600">Loading...</p>
      </div>
    );
  }

  return (
    <div className="relative w-full h-[750px] bg-black">
      {/* Fixed container for video or image */}
      <div className="fixed md:top-[126px] top-[60px] left-0 w-full h-[750px] z-10">
        {/* If `imageVideo` is "0", display video */}
        {aboutUsData.imageVideo === "0" ? (
          <video
            className="w-full h-[750px] object-cover"
            src={`${imgurl}/${aboutUsData.video}`}
            autoPlay
            loop
            muted
            playsInline
            preload="auto"
            poster="/path-to-fallback-image.jpg"
          />
        ) : (
          // If `imageVideo` is "1", display image
          <div className="relative">
            {/* Placeholder shimmer effect for image loading */}
            {!isImageLoaded && (
              <div className="absolute inset-0 bg-gray-200 animate-pulse" />
            )}
            <div className="hidden md:block">
              <img
                className="w-full  h-[750px] object-contain"
                src={`${imgurl}/${aboutUsData.dashboardImage}`}
                alt="Slider"
                loading="lazy"
                onLoad={() => setIsImageLoaded(true)}
              />
              
            </div>

            <div className="block md:hidden">
              <img
                className="w-full  h-[750px] object-contain"
                src={`${imgurl}/${aboutUsData.mobileImage}`}
                alt="Slider"
                loading="lazy"
                onLoad={() => setIsImageLoaded(true)}
              />
            </div>

          </div>
        )}
      </div>
    </div>
  );
};

export default Video_Sections;
