import React, { useState } from 'react';
import Saidbar from '../Saidbar/Saidbar';
import { ApiBaseurl } from '../Credentials/Credentials';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const ForgotPassword = () => {
    const [email, setEmail] = useState('');
    const [oldPassword, setOldPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [message, setMessage] = useState('');

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await fetch(`${ApiBaseurl}/Contractor/forgotPass`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ email, oldPassword, newPassword }),
            });
    
            // Check if the response is OK (status in the range 200–299)
            if (!response.ok) {
                const errorData = await response.json();
                throw new Error(errorData.message || 'Error updating password');
            }
    
            const data = await response.json();
            console.log(data.message);
            toast.success(data.data?.message || data.message);
            setOldPassword("");
            setNewPassword("");
            setEmail("");
            setMessage(data.message);
    
        } catch (error) {
            console.error('Password update error:', error);
            toast.error(error.message || 'Error updating password');
            setMessage(error.message || 'Error updating password');
        }
    };

    return (
        <div>
            <div>
                <Saidbar />
            </div>
            <div className='sm:ml-[250px] px-[30px] pt-[25px]'>
                <div className='mt-[31px]'>
                    <h2 className="text-2xl font-bold mb-4 font-roboto text-[32px] border-b-[2px] pb-[15px] border-black">Change Password
                    </h2>
                </div>
                <h2></h2>
                <form onSubmit={handleSubmit}>
                    <div className='bg-white flex flex-col gap-[15px] sm:w-[40%] w-[100%] border-[1px] m-auto p-[10px]'>
                        <input type="email" value={email} onChange={(e) => setEmail(e.target.value)} required placeholder='Enter Email :' className='w-[100%] p-[10px] outline-none border-[1px] border-black mb-[10px]' />

                        <input type="password" value={oldPassword} onChange={(e) => setOldPassword(e.target.value)} required placeholder='Enter Old Password' className='w-[100%] p-[10px] outline-none border-[1px] border-black mb-[10px]' />

                        <input type="password" value={newPassword} onChange={(e) => setNewPassword(e.target.value)} required placeholder='Enter New Password' className='w-[100%] p-[10px] outline-none border-[1px] border-black mb-[10px]'/>

                        <button type="submit" className='bg-black text-white p-[10px] rounded-sm hover:bg-white hover:text-black hover:border-[1px]'>Submit</button>
                    </div>
                </form>
                {/* {message && <p>{message}</p>} */}
            </div>
            <ToastContainer />
        </div>
    );
};

export default ForgotPassword;
