import React, { useState, useEffect, useRef } from 'react';
import Modal from 'react-modal';
import Saidbar from '../Saidbar/Saidbar';
import { addSliderVideo, getSliderVideo } from '../Api/Api';
import { imgurl } from '../Credentials/Credentials';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

Modal.setAppElement('#root');

const SliderVideo = () => {
    const [file, setFile] = useState(null);
    const [preview, setPreview] = useState(null);
    const [mediaType, setMediaType] = useState(''); // 'image' or 'video'
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const [aboutUsData, setAboutUsData] = useState(null);
    const [mobileImage, setMobileImage] = useState(null); // For mobile view image
    const [dashboardImage, setDashboardImage] = useState(null); // For dashboard view image
    const [mobileImagePreview, setMobileImagePreview] = useState(null); // Preview for mobile view image
    const [dashboardImagePreview, setDashboardImagePreview] = useState(null); // Preview for dashboard view image

    // Create refs for file inputs
    const mobileImageRef = useRef(null);
    const dashboardImageRef = useRef(null);
    const videoFileRef = useRef(null);

    const handleFileChange = (e, type) => {
        const uploadedFile = e.target.files[0];

        // Check file type and size
        if (type === 'mobile' || type === 'dashboard') {
            // For images, check if the file size is greater than 50KB
            if (uploadedFile.size > 50 * 1024) {
                toast.error("Image size exceeds the 50KB limit. Please select a smaller image.");
                resetFileInputs(type);
                return;
            }
        } else {
            // For videos, check if the file size is greater than 4MB
            if (uploadedFile.size > 4 * 1024 * 1024) {
                toast.error("Video size exceeds the 4MB limit. Please select a smaller video.");
                resetFileInputs(type);
                return;
            }
        }

        // If file is valid, set it to the appropriate state
        if (type === 'mobile') {
            setMobileImage(uploadedFile);
            setMobileImagePreview(URL.createObjectURL(uploadedFile)); // Set the preview for mobile image
        } else if (type === 'dashboard') {
            setDashboardImage(uploadedFile);
            setDashboardImagePreview(URL.createObjectURL(uploadedFile)); // Set the preview for dashboard image
        } else {
            setFile(uploadedFile);
            setPreview(URL.createObjectURL(uploadedFile)); // Set the preview for video/image
        }
    };

    const resetFileInputs = (type) => {
        if (type === 'mobile') {
            setMobileImage(null);
            setMobileImagePreview(null);
            mobileImageRef.current.value = '';
        } else if (type === 'dashboard') {
            setDashboardImage(null);
            setDashboardImagePreview(null);
            dashboardImageRef.current.value = '';
        } else {
            setFile(null);
            setPreview(null);
            videoFileRef.current.value = '';
        }
    };


    const handleSubmit = async () => {
        if (!file && mediaType === 'video') {
            toast.error("Please select a video to upload.");
            return;
        }
        if (mediaType === 'image' && (!dashboardImage || !mobileImage)) {
            toast.error("Please select both images (Dashboard and Mobile view).");
            return;
        }
        setLoading(true);

        const formData = new FormData();
        if (mediaType === 'video') {
            formData.append('file', file);
        } else if (mediaType === 'image') {
            formData.append('mobileImage', mobileImage);
            formData.append('dashboardImage', dashboardImage);
        }

        try {
            const response = await addSliderVideo(formData);
            setLoading(false);
            setModalIsOpen(false);
            toast.success(response.message);
            setMediaType("");
            resetFileInputs();
            fetchAboutUsData();
        } catch (error) {
            setLoading(false);
            // toast.error("Failed to upload media.");
        }
    };


    const fetchAboutUsData = async () => {
        try {
            const data = await getSliderVideo();
            setAboutUsData(data.data.data.data[0]);
        } catch (error) {
            console.error('Error fetching About Us data:', error);
        }
    };



    useEffect(() => {
        fetchAboutUsData();
    }, []);

    // console.log("aboutUsData",aboutUsData);

    const closeModal = () => {
        setModalIsOpen(false);
        setMediaType("");
    };

    return (
        <div>
            <Saidbar />
            <div className='sm:ml-[250px] px-[30px] sm:pt-[25px] pt-[60px]'>
                <h2 className="text-2xl font-bold mb-4 font-roboto text-[32px] border-b-[2px] pb-[15px] border-black">
                    Update video OR Image
                </h2>

                {/* Button to open modal */}
                <button onClick={() => setModalIsOpen(true)} className="bg-black py-[15px] px-[25px] text-white font-roboto rounded-[25px] font-bold text-[18px]">
                    {file ? 'Update Media' : 'Add Media'}
                </button>

                {/* Display current media */}
                {/* <div className="mt-8">
                    {aboutUsData && (
                        <div className="bg-gray-100 p-4 rounded shadow-md">
                            {aboutUsData.sliderName.endsWith('.mp4') ? (
                                <video src={`${imgurl}/${aboutUsData.sliderName}`} controls className="w-[50%] h-auto" />
                            ) : (
                                <img src={`${imgurl}/${aboutUsData.sliderName}`} alt="Slider" className="w-[50%] h-auto" />
                            )}
                        </div>
                    )}
                </div> */}

                {/* Modal to choose media type (Image or Video) */}
                <Modal isOpen={modalIsOpen} onRequestClose={closeModal}
                    style={{
                        content: {
                            top: '50%',
                            left: '50%',
                            right: 'auto',
                            bottom: 'auto',
                            marginRight: '-50%',
                            transform: 'translate(-50%, -50%)',
                            width: window.innerWidth < 768 ? '100%' : '50%',
                            maxWidth: '800px',
                            borderRadius: '10px',
                            padding: '20px',
                        }
                    }}>
                    <h2 className="text-2xl font-bold mb-4 font-roboto sm:text-[32px] text-[18px] border-b-[2px] pb-[15px] border-black">
                        {mediaType ? 'Update About Us Content' : 'Choose Media Type'}
                    </h2>

                    {/* Option to select video or image */}
                    {!mediaType && (
                        <div className="mb-4">
                            <button onClick={() => setMediaType('video')} className="bg-black text-white py-[10px] px-[20px] font-roboto rounded-[25px] font-bold">
                                Upload Video
                            </button>
                            <button onClick={() => setMediaType('image')} className="bg-black text-white py-[10px] px-[20px] font-roboto rounded-[25px] font-bold ml-4">
                                Upload Image
                            </button>
                        </div>
                    )}

                    {/* Display media upload options based on media type */}
                    {mediaType === 'image' && (
                        <div>
                            <h3 className="text-xl mb-2">Upload Images</h3>
                            <div className="mb-4">
                                <label className="block mb-2">Dashboard View Image:</label>
                                <input type="file" onChange={(e) => handleFileChange(e, 'dashboard')} accept="image/*" ref={dashboardImageRef} />
                            </div>
                            <div className="mb-4">
                                <label className="block mb-2">Mobile View Image:</label>
                                <input type="file" onChange={(e) => handleFileChange(e, 'mobile')} accept="image/*" ref={mobileImageRef} />
                            </div>
                        </div>
                    )}

                    {mediaType === 'video' && (
                        <div className="mb-4">
                            <label className="block mb-2">Upload Video:</label>
                            <input type="file" onChange={(e) => handleFileChange(e, 'video')} accept="video/*" ref={videoFileRef} />
                        </div>
                    )}

                    {/* Preview of selected media */}
                    {preview && (
                        <div>
                            {file && file.type.startsWith('video') ? (
                                <video src={preview} controls className="w-full h-auto" />
                            ) : (
                                <img src={preview} alt="Preview" className="w-[50%] h-auto" />
                            )}
                        </div>
                    )}

                    <div className='flex gap-[15px]'>
                        <div className="mt-4">
                            <button onClick={handleSubmit} className="bg-black text-white py-[10px] px-[20px] font-roboto rounded-[10px] font-bold">
                                {loading ? 'Uploading...' : 'Submit'}
                            </button>
                        </div>

                        <div className="mt-4">
                            <button onClick={closeModal} className="bg-gray-400 text-white py-[10px] px-[20px] font-roboto rounded-[10px] font-bold">
                                close
                            </button>
                        </div>
                    </div>

                </Modal>
            </div>

            {/* Toast Container for notifications */}
            <ToastContainer />
        </div>
    );
};

export default SliderVideo;
