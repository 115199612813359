import React from 'react';
import Home_Contactus from '../Home_Contactus/Home_Contactus';

const ContactUs = () => {
  return (
    <div>
      <Home_Contactus/>
    </div>
  );
}

export default ContactUs;
