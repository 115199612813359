import React from 'react';
import Home_Aboutus from '../Home_Aboutus/Home_Aboutus';

const About_Us = () => {
  return (
    <div className='bg-[#41414e1a]'>
      <Home_Aboutus/>
    </div>
  );
}

export default About_Us;
